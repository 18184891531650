export const WORDS = [
  'password',                       // level 1
  '68736',                          // level 2
  'god',                            // level 3
  'Password01',                     // level 4
  'ILoveArag0rn!',                  // level 5
  '20130826',                       // level 6
  'dino$aurLover12',                // level 7
  'deMystifier',                    // level 8
  'Correct Horse Battery Staple.',  // level 9
]