const gameStateKey = 'gameState'
const archiveGameStateKey = 'archiveGameState'
const highContrastKey = 'highContrast'
const levelResultsKey = 'levelResults'

type LevelResult = 'win' | 'loss' | null;
type LevelResults = {
  [level: number]: LevelResult;
}

export type StoredGameState = {
  guesses: string[]
  solution: string
}

export type StoredArchiveState = {
  level: string
  result: string
}

export const saveGameStateToLocalStorage = (
  isLatestGame: boolean,
  gameState: StoredGameState
) => {
  const key = isLatestGame ? gameStateKey : archiveGameStateKey
  localStorage.setItem(key, JSON.stringify(gameState))
}

export const saveLevelResult = (
  level: number,
  result: LevelResult
) => {
  const levelResults: LevelResults = JSON.parse(localStorage.getItem(levelResultsKey) || '{}');
  levelResults[level] = result;
  localStorage.setItem('levelResults', JSON.stringify(levelResults))
}

export const loadGameStateFromLocalStorage = (isLatestGame: boolean) => {
  const key = isLatestGame ? gameStateKey : archiveGameStateKey
  const state = localStorage.getItem(key)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

export const setStoredIsHighContrastMode = (isHighContrast: boolean) => {
  if (isHighContrast) {
    localStorage.setItem(highContrastKey, '1')
  } else {
    localStorage.removeItem(highContrastKey)
  }
}

export const getStoredIsHighContrastMode = () => {
  const highContrast = localStorage.getItem(highContrastKey)
  return highContrast === '1'
}

