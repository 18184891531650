export const GAME_TITLE = "Passwordle"

export const WIN_MESSAGES = ['Account unlocked - well done!', 'You got their password!', 'Authentication successful!', 'Identity confirmed: ACCESS GRANTED!', 'Password accepted, logging in...']
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters'
export const WORD_NOT_FOUND_MESSAGE = 'Word not found'
export const HARD_MODE_ALERT_MESSAGE =
  'Hard Mode can be enabled only at the start!'
export const HARD_MODE_DESCRIPTION =
  'Any revealed hints must be used in subsequent guesses'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'For improved color vision'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `The password was: ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Del'
export const NEW_WORD_TEXT = 'New word in'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser."
export const LEVELPICKER_TITLE = 'Choose a level'
export const LEVELPICKER_CHOOSE_TEXT = 'Select'
export const HASH_MESSAGES = ['Nope. Maybe try again?', 'Hash match failed...', 'Authentication rejected.', 'Trickier than you expected?', 'Password login denied.', 'Incorrect credentials; ACCESS DENIED.']