import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { Fragment } from 'react'
import { CharStatus } from '../../lib/statuses'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const handleLevelClick = (level: number) => {
  localStorage.setItem('gameState', JSON.stringify({}));
  if ( window.location.href.includes('index.html') ) {
    window.location.href = `./index.html?l=${level}`
  } else {
    window.location.href = `./?l=${level}`
  }
};

function isLevelCompleted(num: number): boolean {
  const levelResults = JSON.parse(localStorage.getItem('levelResults') || '{}');
  return levelResults.hasOwnProperty(num.toString());
}

function getLevelResult(num: number): CharStatus {
  const levelResults = JSON.parse(localStorage.getItem('levelResults') || '{}');
  const victory = (levelResults[num] === 'win')
  if (!isLevelCompleted(num)) { return "absent" }
  return victory ? "correct" : "loss";
}

export const LevelSelectModal = ({ isOpen, handleClose }: Props) => {

  const levelButtons = Array.from({length: 9}, (_, i) => i + 1).map((level) => (
    <Fragment
      key={level.toString()}
    >
      <button className="mb-4"
        type="button"
        disabled={isLevelCompleted(level)}
        onClick={() => handleLevelClick(level)}
      >
        <Cell
          isRevealing={isLevelCompleted(level)}
          isCompleted={true}
          // TODO: colour the cell status by level history in localStorage
          value={level.toString()}
          status={getLevelResult(level)}
        />
      </button>{' '}
    </Fragment>
  ));

  return (
    <BaseModal title="Choose a Level" isOpen={isOpen} handleClose={handleClose}>
    <div className="mb-1 mt-4 flex justify-center">
      <div className="level-buttons mb-1 mt-4 flex-row">{levelButtons}</div>
    </div>
    </BaseModal>
  )
};
