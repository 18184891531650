export const LEVELS: string[] = [

    
    // Level 1
    `<!-- Level 1 -->
    • The user <i>Jessie</i> set the password for their work laptop </br>
    • All you know is that they hate computers and are very lazy </br>
    • Don't overthink it, this is the first level! </br>
    <!-- End Level 1 -->`, 

    // Level 2
    `<!-- Level 2 -->
    • The password is a 5 digit PIN </br>
    • It is used to lock the target's <b>SecureVault</b> phone app </br>
    • This app does not let consecutive digits repeat in a passcode </br>
    &nbsp&nbsp&nbsp<i>eg. <span style="color:green">12134</span> is valid </br>
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp <span style="color:red">73346</span> is not. <br></i>
    • You get six tries before the application locks down!
    <!-- End Level 2 -->`, 
    
    // Level 3
    `<!-- Level 3 -->
    • An ancient financial system you are targetting has a <b>root</b> user </br>
    • The database is a 1981 SPARC server, that does not support special characters </br>
    • The person who owns the account a big fan of the 1995 movie <b>Hackers</b> </br>
    • You can see in their inbox a message titled: <br>
    <br>&nbsp&nbsp&nbsp<i>"Carefully prepared memo on commonly-used passwords"</i>
    <!-- End Level 3 -->`, 
    
    // Level 4
    `<!-- Level 4 -->
    • <i>Jessie</i> from Level 1 has come to your attention again. </br>
    • You try their old password but it fails to authenticate </br>
    • Reading company emails, you see their new IT policy mandates: <br>
    &nbsp&nbsp&nbsp - at least one <b>lowercase</b> letter; and </br>
    &nbsp&nbsp&nbsp - at least one <b>uppercase</b> letter; and </br>
    &nbsp&nbsp&nbsp - at least two <b>numbers</b>. </br>
    • This user is still lazy.. So what is their new password? </br>
    <!-- End Level 4 -->`, 
    
    // Level 5
    `<!-- Level 5 -->
    • You have forgotten your own password! </br>
    • Usually you write them down on a sticky note under your keyboard ...</i></br>
    • <b>Success</b>! You find the following: </br>
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<i><s>I<3Aragorn</s> </br>
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<i><s>AragornIsMyKing!</s> </br>
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<i><s>KingOfGondor!</s> </br>
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<i><s>#ILoveStrider</s> </br>
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<i>HeirOfIsildur</br>
    <!-- End Level 5 -->`, 
    
    // Level 6
    `<!-- Level 6 -->
    • A violent revolutionary is planning an attack on a peaceful country </br>
    • Reading through the intelligence available, you discover they have a grandchild: </br>
    &nbsp&nbsp&nbsp - <b>Name: &nbsp</b> River Quartz McEvylPearant </br>
    &nbsp&nbsp&nbsp - <b>DOB: &nbsp&nbsp&nbsp&nbsp</b> August 26, 2013. </br>
    &nbsp&nbsp&nbsp - <b>Other: &nbsp</b> Likes chocolate, running races, and rainbows. </br>
    • What is the 8 digit PIN to the revolutionary's mobile phone? </br>
    <!-- End Level 6 -->`, 
    
    // Level 7
    `<!-- Level 7 -->
    • Target <b>Sasha Sinister</b> has a login credential username: <b>Velociraptor</b> </br>
    • Their accounts on other systems are named: <b>Stegosaurus</b>, <b>Triceratops</b>, and <b>Pterodactyl</b> </br>
    • Sasha's organisation forces password reset each month </br>
    • What was their password on 25 December, 2022? </br>
    <!-- End Level 7 -->`, 
    
    // Level 8
    `<!-- Level 8 -->
    • You have received intelligence that included a photograph of your target's desk</br>
    • Underneath their stained coffee mug is a newspaper from earlier this month</br>
    • The puzzle pages are on top, with the following clue circled in the Times Cryptic: </br>
    <span style="color:orange">
    <br><i>After half tide, <u>M</u>rs. Fire's husband heard an individual 
    clearing up perplexing circumstances (11)</i></span>
    <!-- End Level 8 -->`, 
    
    // Level 9 
    `<!-- Level 9 -->
    • An avid reader of the xkcd webcomic has encrypted a critical file </br>
    • The file is called <b>936.zip</b> </br>
    • They needed a simple way to share the password with their covert agent overseas </br>
    • ... but this user is a stickler for proper grammar. </br>
    • Draw inspiration from your surroundings! (or google) </br>
    <!-- End Level 9 -->`, 
]
