import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Passwordle: How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">
        Read the intelligence data provided. Guess the password in limited tries!
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        After each guess, the color of the tiles will change to show how close your guess was.
      </p>
      <p className="text-xs mt-4 text-gray-500 dark:text-gray-300">
        ~
      </p>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="T"
          status="correct"
        />
        <Cell value="h" isCompleted={true} />
        <Cell value="i" isCompleted={true} />
        <Cell value="n" isCompleted={true} />
        <Cell value="k" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter 'T' is in the password and in the correct spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="$" isCompleted={true} />
        <Cell value="C" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
        <Cell value="Y" isCompleted={true} />
        <Cell value="P" isCompleted={true} />
        <Cell value="T" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="^"
          status="present"
        />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The symbol '^' is in the password but in the wrong spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="Z" isCompleted={true} />
        <Cell value="x" isCompleted={true} />
        <Cell value="c" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="V" status="absent" />
        <Cell value="!" isCompleted={true} />
        <Cell value="2" isCompleted={true} />
        <Cell value="3" isCompleted={true} />
        <Cell value="$" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The capital letter 'V' is not in the password in any spot.
      </p>
      <p className="text-xs mt-4 text-gray-500 dark:text-gray-300">
        ~
      </p>
      <p className="mt-4 text-xs italic text-gray-500 dark:text-gray-300">
        This is an open source mix-up of the word guessing game we all know and love.
      </p>
      <p className="text-xs italic text-gray-500 dark:text-gray-300">
        Thanks to  react-wordle by cwackerfuss @ github.
      </p>
    </BaseModal>
  )
}
