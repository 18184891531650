import {
  AdjustmentsIcon,
  CogIcon,
  InformationCircleIcon,
  UserAddIcon,
} from '@heroicons/react/outline'

import classnames from 'classnames'
import { GAME_TITLE } from '../../constants/strings'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsLevelSelectOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
  toggleKeyboard: () => void
  isKeyboardActive: boolean
}

const handleClearLocalStorage = () => {
  const theme = localStorage.getItem("theme"); // get the value of the 'theme' entry
  localStorage.clear(); // clear all localStorage entries
  if (theme) localStorage.setItem("theme", theme); // set the 'theme' entry back to its original value (if it exists)
  if ( window.location.href.includes('index.html') ) {
    window.location.href = `./index.html?l=1`
  } else {
    window.location.href = `./?l=1`
  }
  return true
};

export const Navbar = ({
  setIsInfoModalOpen,
  setIsLevelSelectOpen,
  setIsSettingsModalOpen,
  toggleKeyboard,
  isKeyboardActive,
}: Props) => {

  const classes = classnames(
    'select-none ml-3 text-xl font-bold dark:text-white rounded',
    {
      'outline outline-2 outline-amber-500 dark:outline-green-400': isKeyboardActive,
    }
  )

  return (
    <div className="navbar">
      <div className="navbar-content px-5 short:h-auto">
        <div className="flex">
          <InformationCircleIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
          <AdjustmentsIcon
            className="ml-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsLevelSelectOpen(true)}
          />
          <div className={classes}>
            <button onClick={() => toggleKeyboard()}>{'⌨️'}</button>
          </div>
        </div>
        <p className="text-xl font-bold dark:text-white">{GAME_TITLE}</p>
        <div className="right-icons">
          <UserAddIcon
            className="ml-9 mr-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => handleClearLocalStorage() }
          />
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}

// 🖮 - Doesn't work on mobile (not in most fonts)